import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import React, { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useFormContext } from "react-hook-form";
import CONSTANTS from "../constants";
import "moment/locale/lv";
moment.locale(CONSTANTS.DEFAULT_LOCALE);

const CustomDatePickerWithAge = ({
  name,
  label,
  defaultValue,
  inputLabelProps,
  inputProps,
  testId,
  ...props
}) => {
  const minDate = moment(CONSTANTS.MIN_DATE);
  const maxDate = moment();

  const [date, setDate] = useState(defaultValue ? moment(defaultValue) : maxDate);
  const [age, setAge] = useState(null);

  const {
    formState: { errors },
    register,
    setValue,
  } = useFormContext();

  const handleDate = (selectedDate) => {
    const newDate = selectedDate ? moment(selectedDate) : null;
    setDate(newDate);
    const newAge = newDate ? moment().diff(newDate, "years") : null;
    setAge(newAge);
    setValue(name, newDate ? newDate.toDate() : null, { shouldDirty: true, shouldValidate: true });
  };

  useEffect(() => {
    register(name, {
      validate: (value) =>
        value && moment().diff(value, "years") > 17
          ? true
          : "Lietotājam ir jābūt vismaz 18 gadus vecam",
    });

    if (defaultValue) {
      const initialDate = moment(defaultValue);
      setDate(initialDate);
      setValue(name, initialDate.toDate());
      setAge(moment().diff(initialDate, "years"));
    }
  }, [defaultValue, register, name, setValue]);

  return (
    <>
      <Box
        display="flex"
        gap="1.5rem"
        justifyContent="space-between"
        flexDirection={{ md: "row", xs: "column" }}
      >
        <Box flex="1">
          <FormControl size="small" fullWidth {...props}>
            <DatePicker
              label={label}
              InputProps={{ ...inputProps, "data-testid": testId }}
              value={date}
              onChange={handleDate}
              {...props}
              minDate={minDate}
              maxDate={maxDate}
              disableFuture
              textField={(params) => (
                <TextField
                  {...params}
                  size="small"
                  InputLabelProps={inputLabelProps}
                  error={!!errors[name]}
                  helperText={errors[name]?.message || ""}
                  inputProps={{
                    ...params.inputProps,
                    "data-testid": testId,
                    ...inputProps,
                  }}
                />
              )}
              {...props}
            />
            <FormHelperText error>
              {errors.dateBirth ? errors.dateBirth.message : ""}
            </FormHelperText>
          </FormControl>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flex="1"
        >
          {age && (
            <>
              <Typography variant="h5">Aprēķinātais vecums:</Typography>
              <Typography
                variant="h5"
                color={errors.dateBirth ? "error" : "green"}
              >
                {age} gadi
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CustomDatePickerWithAge;
