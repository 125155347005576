import http from "../api/http";
import serviceUtil from "../utils/service-util";

const consentService = {
  deleteConsent: async (consentId) => {
    const response = await http.backendInstance.delete(`/api/consent/consent/${consentId}`);
    return response.data;
  },
  getConsents: async (queryParams) => {
    if (!serviceUtil.isObject(queryParams))
      return [];

    queryParams = serviceUtil.assignPageSizeAndPageNumberIfNotPresent(queryParams);

    const response = await http.backendInstance.get("/api/consent/all-consents", {
      params: queryParams,
    });

    return response.data;
  },
  getLastConsent: async (researchId, consentStatus) => {
    const response = await http.backendInstance.get(`/api/consent/last-consent/`,
      { params: { researchDraftId: researchId, status: consentStatus } }
    );
    return response.data;
  },
  saveInformedConsent: async (informedConsent) => {
    const response = await http.backendInstance.post("/api/consent/", informedConsent);

    return response.data;
  },
  publishConsent: async (consentId) => {
    const response = await http.backendInstance.post(`/api/consent/publish-consent/${consentId}`);

    return response.data;
  },
  getConsentPermissions: async (consentId, signal) => {
    const response = await http.backendInstance.get(`/api/consent/permissions/${consentId}`, {signal});

    return response.data;
  },

  createConsent: async (consentToCreate) => {
    const response = await http.backendInstance.post("/api/consent/consent",
    {...consentToCreate}
    );

    return response.data;
  },

  updateConsent: async (consentModelToUpdate) => {
    const response = await http.backendInstance.put("/api/consent/consent",
    {...consentModelToUpdate}
    );

    return response.data;
  },

  getConsentById: async (consentId, signal) => {
    const response = await http.backendInstance.get(`/api/consent/consent/${consentId}`,{signal});

    return response.data;
  },

  getLatestConsentVersion: async (consentType, signal, researchId = null,) => {
    const response = await http.backendInstance.get("/api/consent/latest-consent-version",{
      params: {type: consentType, researchId},
      signal
    });

    return response.data;
  },

  getIdOfConsentWithStatusPublished: async (consentType, signal, researchId = null) => {
    const response = await http.backendInstance.get("/api/consent/consent-with-status-published",{
      params: {type: consentType, researchId},
      signal
    });

    return response.data;
  },
  getConsentTypeSelectListItems: async (signal) => {
    const response = await http.backendInstance.get("/api/consent/consent-types-select-list-items", {
      signal,
    });
    
    return response.data;
  },
  downloadConsents: async (fileName, attachmentIds) => {
    const response = await http.backendInstance.post(
      "/api/permission/file-retrieval",
      {
        fileName,
        attachmentIds,
      },
      {
        responseType: "blob",
        headers: {
          Accept: "*/*",
        },
      }
    );

    return response.data;
  }
}

export default consentService;
