import http from "../api/http";

const classifierService = {
  getClassifierList: async (classifierTableName, queryParams, signal) => {
    const response = await http.backendInstance.get(
      "/api/classifier/get-clasificator-list",
      {
        params: { classifierTableName, ...queryParams },
        signal,
      }
    );

    return response.data;
  },
  getClassifierById: async (classifierTableName, classifierId, signal) => {
    const response = await http.backendInstance.get(
      "/api/classifier/specific-classificator-from-table",
      {
        params: { classifierTableName, classifierId },
        signal,
      }
    );

    return response.data;
  },

  isClassifierCodeValid: async (
    classifierTableName,
    classifierCode,
    signal
  ) => {
    const response = await http.backendInstance.get(
      "/api/classifier/is-valid-classifier-code",
      {
        params: { classifierTableName, classifierCode },
        signal,
      }
    );
    return response.data;
  },
};

export default classifierService;
