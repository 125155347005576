import http from "../api/http";

const personService = {
  isPersonCodeRegistered: async (personCodeToCheck) => {
    const response = await http.backendInstance.get(
      "/api/person/is-person-code-registered",
      { params: { personCode: personCodeToCheck } }
    );

    return response.data;
  },
  deletePerson: async (personId) => {
    const response = await http.backendInstance.delete(
      `/api/person/${personId}`
    );

    return response.data;
  },
  isUniqueIdRegistered: async (uniqueIdToCheck) => {
    const response = await http.backendInstance.get(
      `/api/person/validate-participant-uniqueId/${uniqueIdToCheck}`
    );

    return response.data;
  },
};

export default personService;
