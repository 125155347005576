import { UserRoleNameEnum } from "./user-role-name";

const UserRoleCodeEnum = Object.freeze({
  Admin: "ADM",
  Participant: "PART",
  Researcher: "RES",
  Medic: "MEDIC"
});

const UserRoleCodeFromNameMap = new Map([
  [UserRoleNameEnum.Participant, UserRoleCodeEnum.Participant],
  [UserRoleNameEnum.Admin, UserRoleCodeEnum.Admin],
  [UserRoleNameEnum.Researcher, UserRoleCodeEnum.Researcher],
  [UserRoleNameEnum.Medic, UserRoleCodeEnum.Medic]
]);

export { UserRoleCodeEnum, UserRoleCodeFromNameMap };
