import { Navigate, useLocation, Outlet } from "react-router-dom";
import useToken from "../hooks/useToken";
import { useAccessToken } from "../hooks";
import { SessionStorageKey } from "../enums";

export const HomePrivateRoute = ({ children }) => {
  const location = useLocation();

  const { token } = useToken();
  const { accessToken, isAccessTokenExpired } = useAccessToken();

  let currentUserToken = token;

  let isAuthenticatedStorage = false;

  if (!isAccessTokenExpired() && accessToken?.length) {
    isAuthenticatedStorage = true;
    currentUserToken = accessToken;
    sessionStorage.setItem(SessionStorageKey.IsAuthenticated, true);
  } else {
    sessionStorage.setItem(SessionStorageKey.IsAuthenticated, false);
    isAuthenticatedStorage = false;
  }

  if (location.pathname === "/" && isAuthenticatedStorage && currentUserToken?.length) {
    return <Navigate to="/dashboard" />;
  }

  return children ? children : <Outlet />;
};
