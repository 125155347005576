import * as Components from "../components";
import * as Pages from "../pages";
import { HomePrivateRoute } from "./HomePrivateRoute";
import { PrivateRoleRoute } from "./PrivateRoleRoute";
import { UserRoleNameEnum } from "../enums";
import PrivateRoute from "./PrivateRoute";
import { useRoutes } from "react-router-dom";

export default function Router() {
  let element = useRoutes([
    {
      path: "/",
      element: <Components.Layout />,
      errorElement: <Pages.ErrorPageView />,
      children: [
        { index: true, element: <HomePrivateRoute><Pages.Home /></HomePrivateRoute> },
        { path: "about", element: <Pages.AboutView /> },
        { path: "contacts", element: <Pages.Contacts /> },
        { path: "more-information", element: <Pages.Moreinformation /> },
        { path: "faq", element: <Pages.FAQ /> },
        { path: "terms-of-use", element: <Pages.TermsOfUse /> },
        { path: "public/researches", element: <Pages.PublicResearchView /> },
        { path: "public/researches/:researchId/details", element: <Pages.PublicResearchDetailsView /> },
        { path: "public/researches/:researchId/details/:personId", element: <Pages.PublicResearchDetailsView /> },

        {
          element: <PrivateRoute />,
          children:
            [
              { path: "consents/consent-fill", element: <Pages.ConsentFillFormView /> },
              { path: "consents/:consentId/edit", element: <Pages.ConsentUpsertView /> },
              { path: "consents/consent-create", element: <Pages.ConsentUpsertView /> },
              { path: "consents/:consentId/take-over", element: <Pages.ConsentUpsertView /> },
              { path: "consents/informed-consent-fill", element: <Pages.ConsentFillFormView /> },
              { path: "consents/user-submitted-consents", element: <Pages.UserSubmittedConsentsView /> },
              { path: "consents/research/:researchId/add", element: <Pages.ConsentResearchAdd /> },
              { path: "consents/versions", element: <Pages.ConsentVersionsView /> },
              { path: "consents/biobank/fill", element: <Pages.ConsentBiobankFillingView /> },
              { path: "consents/biobank/filled/:id", element: <Pages.ConsentFilledBiobankView /> },
              { path: "consents/biobank/add", element: <Pages.ConsentBiobankAdd /> },
              { path: "consents/research/nurse", element: <Pages.ConsentResearchNurseFillView /> },

              { path: "signed-consents", element: <Pages.SignedConsentView /> },

              { path: "permissions", element: <Pages.PermissionListView /> },
              { path: "permissions/create", element: <Pages.PermissionUpsertView /> },
              { path: "permissions/:id/edit", element: <Pages.PermissionUpsertView /> },

              { path: "biobank", element: <Pages.BiobankHomeView /> },
              { path: "biobank/biobank-consent-create", element: <Pages.ConsentUpsertView /> },
              { path: "participant/research/:researchId/details", element: <Pages.ParticipantResearchTabsView /> },

              { path: "user-profile/edit", element: <Pages.UserProfileEdit /> },
              { path: "user-profile", element: <Pages.UserProfile /> },
              { path: "dashboard", element: <Pages.UsersDashboardView /> },
              { path: "users/:userId", element: <Pages.UserEdit /> }, //strange probably only be for admin 

              { path: "referrals", element: <Pages.ReferralList /> },
              { path: "referrals/add", element: <Pages.ReferralAdd /> },
              { path: "referrals/:referralId/edit", element: <Pages.ReferralEdit /> },
              { path: "referrals/:referralId/view", element: <Pages.ReferralView /> },

              { path: "persons", element: <Pages.PersonList /> },
              { path: "persons/add", element: <Pages.PersonAdd /> },
              { path: "persons/:personIds/edit", element: <Pages.PersonEditView /> },
              { path: "classifiers/:name/details", element: <Pages.ClassifierDetail /> },
              { path: "classifiers", element: <Pages.ClassifierList /> },

              { path: "change-role", element: <Pages.Role /> },

              { path: "researches", element: <Pages.ResearchView /> },
              { path: "researches/:researchId/edit", element: <Pages.ResearchAdd /> },
              { path: "researches/:researchId/details", element: <Pages.ResearchDetail /> },
              { path: "researches/add", element: <Pages.ResearchAdd /> },

              { path: "notifications", element: <Pages.NotificationsView /> },
              { path: "notifications/:id/read", element: <Pages.NotificationsView /> },
              { path: "notifications/received", element: <Pages.NotificationsView /> },
              { path: "notifications/sent", element: <Pages.NotificationsView /> },
              { path: "notifications/archived", element: <Pages.NotificationsView /> },
              { path: "notifications/create", element: <Pages.NotificationsView /> },

              { path: "cms/system-notifications/create", element: <Pages.SysNotificationAddView /> }, // it something different from cms
              { path: "cms/system-notifications/:id/edit", element: <Pages.SysNotificationEditView /> },

              { path: "questions", element: <Pages.QuestionView /> },
              { path: "surveys", element: <Pages.SurveyView /> },
              { path: "surveys/participant-surveys", element: <Pages.ParticipantSurveyView /> },

              {
                element: <PrivateRoute allowedRoleName={UserRoleNameEnum.Admin} />,
                children:
                  [
                    { path: "user-management/settings", element: <Pages.AdminSettingsView /> },
                    { path: "user-management/users", element: <Pages.AdminSettingsView /> },
                    { path: "user-management/rights", element: <Pages.AdminSettingsView /> },
                    { path: "user-management/groups", element: <Pages.AdminSettingsView /> },
                    { path: "user-management/group-rights", element: <Pages.AdminSettingsView /> },
                    { path: "user-management/audit", element: <Pages.AdminSettingsView /> },
                    { path: "user-management/create-user", element: <Pages.UserCreate /> },
                    {
                      path: "cms", element: <Pages.ContentManagementLayoutView />,
                      children:
                        [
                          { path: "home", element: <Pages.HomeCmsView /> },
                          { path: "about", element: <Pages.AboutUsCmsView /> },
                          { path: "contacts", element: <Pages.ContactsCms /> },
                          { path: "useful-links", element: <Pages.UsefulLinksCms /> },
                          { path: "system-notifications", element: <Pages.SysNotificationsView /> },
                          { path: "translations", element: <Pages.TranslationsView /> },
                          { path: "system-management", element: <Pages.SystemManagerCms /> },
                          {
                            element: <Pages.MoreInformationCmsLayoutView />,
                            children:
                              [
                                { path: "more-information", element: <Pages.MoreInformationCmsDraggableListView /> },
                                { path: "more-information/groups/add", element: <Pages.MoreInformationAddGroupItemView /> },
                                { path: "more-information/groups/:groupId/item/add", element: <Pages.MoreInformationAddGroupItemView /> },
                                { path: "more-information/groups/:groupId/edit", element: <Pages.MoreInformationCmsEditGroupView /> },
                                { path: "more-information/groups/:groupId/item/:itemId/edit", element: <Pages.MoreInformationEditGroupItemView /> },
                              ]
                          },
                          {
                            element: <Pages.FAQCms />,
                            children:
                              [
                                { path: "faq", element: <Pages.FAQCmsListView /> },
                                { path: "faq/question-groups/add", element: <Pages.FAQAddGroupView /> },
                                { path: "faq/question-groups/:groupId/item/add", element: <Pages.FAQAddGroupItemView /> },
                                { path: "faq/question-groups/:groupId/edit", element: <Pages.FAQCmsEditGroupView /> },
                                { path: "faq/question-groups/:groupId/item/:itemId/edit", element: <Pages.FAQEditGroupItemView /> },
                              ]
                          },
                          {
                            element: <Pages.TermsOfUseCms />,
                            children:
                              [
                                { path: "terms-of-use", element: <Pages.TermsOfUseCmsList /> },
                                { path: "terms-of-use/add", element: <Pages.TermsOfUseCmsAdd /> }
                              ]
                          },
                        ]
                    },
                  ]
              },

              { path: '*', element: <Pages.NotFoundView /> },
            ]
        },
      ]
    },
    /* wihtout upper tab */
    {
      element: <Components.ProfileLayout />, errorElement: <Pages.ErrorPageView />
      , children:
        [
          { path: "role", element: <PrivateRoleRoute><Pages.Role /></PrivateRoleRoute>},
          { path: "participants/add", element: <Pages.ParticipantAdd /> },
          { path: "participants/add-using-existing-person/:personId", element: <Pages.ParticipantAdd /> },
          { path: "refresh-password", element: <Pages.PasswordRefresh /> },
          { path: "reset-password", element: <Pages.PasswordReset /> },
          { path: "set-password", element: <Pages.PasswordSet /> },
          { path: "latvija-lv", element: <Pages.LatvijaLv /> },
        ]
    },
  ]);

  return element;
}