import { DatePicker } from "@mui/x-date-pickers";
import React from "react";
import moment from "moment";
import { useFormContext, Controller } from "react-hook-form";
import CONSTANTS from "../constants";
import { Box, Grid, FormHelperText, FormControl, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const UtcCustomDatePicker = React.forwardRef(({
  title = "",
  label,
  name,
  defaultValue = null,
  required,
  rules,
  fullWidth,
  disablePast = false,
  ...props
}, ref) => {

  const {
    formState: { errors },
    control,
  } = useFormContext();
  const theme = useTheme();

  const primaryTextColor = theme.palette.text.primary;
  const errorColor = theme.palette.error.main;
  const defaultMinDate = moment.utc(CONSTANTS.MIN_DATE);
  
  return (
    <>
      <FormControl size="small" fullWidth={fullWidth} {...props}>
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue ? moment(defaultValue).utc(true) : null}
          rules={{ required: required ? "Lauks ir obligāts" : false, ...rules }}
          render={({ field: { onBlur, onChange, value } }) => (
            <Box>
              <Grid container direction="column">
                {title && (
                  <Grid item>
                    <Typography sx={{ color: errors[name] ? errorColor : primaryTextColor }}>
                      {title}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12} xl={4}>
                  <DatePicker
                    disablePast={disablePast}
                    label={label}
                    minDate={defaultMinDate}
                    onChange={(date) => {
                         const newDate = date && date?.isValid() ? date.utc(true).startOf("day") : null; // Set to start of day in UTC
                         onChange(newDate);
                         onBlur();
                    }}
                    value={(value && moment?.isMoment(value)) ? value?.utc(true) : null}
                    inputFormat={CONSTANTS.DATE_FORMAT}
                    ref={ref}
                    {...props}
                    textField={(params) => (
                      <TextField
                        fullWidth={fullWidth}
                        size="small"
                        {...params}
                        error={Boolean(errors[name])}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        />
        <FormHelperText error>{errors[name]?.message}</FormHelperText>
      </FormControl>
    </>
  );
});

export default UtcCustomDatePicker;
