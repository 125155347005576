import Typography from "@mui/material/Typography";
import React from "react";
import { GridOverlay } from "@mui/x-data-grid";

export const NoResultsOverlay = () => {
  return (
    <GridOverlay>
      <Typography>Nav rezultātu</Typography>
    </GridOverlay>
  );
};
