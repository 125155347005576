import http from "../api/http";

const registrationCodeService = {
    getRegistrationCodeTableRecords: async (queryParams, signal) => {
        const response = await http.backendInstance.get("/api/regcode/person-regcodes", {
            params: queryParams,
            signal
        });

        return response.data;
    },

    detachRegCode: async (regCodeId) => {
        const response = await http.backendInstance.delete(`/api/regcode/${regCodeId}`);

        return response.data;
    },

    attachRegCodeToPerson: async (regCodeToAttach, personId) => {
        const response = await http.backendInstance.put("/api/regcode/linkPerson", {
            personId: personId,
            registrationCode: regCodeToAttach
        });

        return response.data;
    },
    isRegistrationCodeAlreadyAttachedToPerson: async (registrationCode, personId) => {
        const response = await  http.backendInstance.get("/api/regcode/is-registration-code-already-attachet-to-person",{
            params: { personId: personId, registrationCode: registrationCode }
        });

        return response.data;
    }
};

export default registrationCodeService;
