import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import instance from "../api/http";
import {
  setAuthenticated,
  setAuthLoading,
  setPersonId,
  setUsername,
  unloadAuthData,
} from "../redux/actions/auth";
import { setUserRoles } from "../redux/actions/auth";
import { useCodeToMessage } from "./useCodeToMessage";
import { setBearerHeader } from "../api/auth/auth";
import { unloadLatvijaLvCredentials } from "../redux/actions/participant";
import useAccessToken from "./useAccessToken";
import { SessionStorageKey } from "../enums";

const useAuth = () => {
  const { setAccessToken } = useAccessToken();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [alertSeverity, setAlertSeverity] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const isLoading = useSelector((state) => state.Auth.isLoading);

  const isAuthenticated = useSelector((state) => state.Auth.isAuthenticated);

  const { findMessageByCode } = useCodeToMessage();

  const clearAuthData = () => {
    setBearerHeader("");
    sessionStorage.clear();
    dispatch(unloadAuthData());
    dispatch(unloadLatvijaLvCredentials());
  }

  const authenticate = async (formData) => {
    try {
      dispatch(setAuthLoading(true));
      const { data } = await instance.authInstance.post(
        "/api/auth/authenticate",
        formData
      );
      instance.authInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${data.idToken}`;

      dispatch(setAuthenticated(true));
      dispatch(setUsername(formData.username));

      sessionStorage.setItem(SessionStorageKey.IsRoleSelecting, true);
      sessionStorage.setItem(SessionStorageKey.UserName, formData.username);
      setAccessToken(data.idToken);

      const requestRoles = await instance.authInstance.get("/api/auth/roles");

      dispatch(setUserRoles(requestRoles.data));
      dispatch(setPersonId(data.personId));
      dispatch(setAuthLoading(false));

      navigate("/role");

      return;

    } catch (errorResp) {
      dispatch(setAuthLoading(false));

      setAlertSeverity("error");

      if (errorResp.response?.data?.hasError) {
        let { error } = errorResp.response.data;

        if (error === "ErrorAlertUserIsBlocked") setAlertSeverity("error");
        else if (error === "ErrorAlertWrongUsernameOrPassword")
          setAlertSeverity("error");
        else if (error === "CautionAlertPasswordHasExpired") {
          instance.authInstance.defaults.headers.common["Authorization"] = `Bearer ${errorResp.response.data.data.idToken}`;
          navigate("/refresh-password", { replace: false });

          return;
        } else error = "ErrorAlertUnknownError";

        setErrorMessage(findMessageByCode(error));
      } else setErrorMessage(findMessageByCode("ErrorAlertUnknownError"));
    }
  };

  return {
    authenticate,
    clearAuthData,
    isLoading,
    alertSeverity,
    isAuthenticated,
    errorMessage,
  };
};

export default useAuth;
