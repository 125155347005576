import http from "../api/http";
import serviceUtil from "../utils/service-util";
import axios from "axios";

const surveyService = {
  getSurveySelectListItems: async (queryParams, signal) => {
    const response = await http.backendInstance.get("/api/survey/survey-select-list-items", {
      params: { ...queryParams },
      signal,
    });

    return response.data;
  },
  createSurvey: async (surveyToCreate, signal) => {
    const response = await http.backendInstance.post("/api/survey/survey",
      { ...surveyToCreate },
      signal
    );

    return response.data;
  },
  updateSurvey: async (surveyToUpdate, signal) => {
    const response = await http.backendInstance.put("/api/survey/survey",
      { ...surveyToUpdate },
      signal
    );

    return response.data;
  },
  submitForPublicationSurvey: async (surveyId) => {
    const response = await http.backendInstance.post(`/api/survey/submit-for-publication-survey/${surveyId}`);

    return response.data;
  },
  publishSurvey: async (surveyId) => {
    const response = await http.backendInstance.post(`/api/survey/publish-survey/${surveyId}`);

    return response.data;
  },
  createSurveyResponse: async (surveyResponseToCreate, signal) => {
    const response = await http.backendInstance.post("/api/survey/survey-response",
      { ...surveyResponseToCreate },
      signal
    );

    return response.data;
  },
  updateSurveyResponse: async (surveyResponseToUpdate, signal) => {
    const response = await http.backendInstance.put("/api/survey/survey-response",
      { ...surveyResponseToUpdate },
      signal
    );

    return response.data;
  },
  saveSurveyResponse: async (surveyResponseToCreate, signal) => {
    const response = await http.backendInstance.post("/api/survey/survey-response-draft",
      { ...surveyResponseToCreate },
      signal
    );

    return response.data;
  },
  getSurveyResponseByTaskId: async (surveyTaskId, signal) => {
    const response = await http.backendInstance.get(`/api/survey/survey-response/${surveyTaskId}`,
      signal
    );

    return response.data;
  },
  withdrawSurveyResponse: async (surveyResponseId) => {
    const response = await http.backendInstance.put(`/api/survey/withdraw/${surveyResponseId}`);

    return response.data;
  },
  getSurveyById: async (surveyId, signal) => {
    if (!surveyId) {
      console.warn("Survey ID is not provided. Returning an empty object.");
      return {};
    }

    const response = await http.backendInstance.get(`/api/survey/${surveyId}`, {
      signal
    });

    return response.data;
  },
  getSurveyBySurveyTaskId: async (surveyTaskId, signal) => {
    const response = await http.backendInstance.get(`/api/survey/survey-by-task-id/${surveyTaskId}`,
      signal
    );

    return response.data;
  },
  getParticipantHasSurveyTask: async (queryParams, signal) => {
    const response = await http.backendInstance.get("/api/survey/check-survey-task", {
      params: { ...queryParams },
      signal,
    });

    return response.data;
  },
  getSurveyResponseById: async (surveyId, signal) => {
    const response = await http.backendInstance.get(`/api/survey/response/${surveyId}`,
      signal
    );

    return response.data;
  },
  getResearcherSurveys: async (queryParams, signal) => {
    if (!serviceUtil.isObject(queryParams))
      return [];

    queryParams = serviceUtil.assignPageSizeAndPageNumberIfNotPresent(queryParams);

    const response = await http.backendInstance.get("/api/survey/researcher-survey-list", {
      params: { ...queryParams },
      signal
    });

    return response.data;
  },
  getResearcherSurveyResponses: async (queryParams, signal) => {
    if (!serviceUtil.isObject(queryParams)) return [];

    queryParams = serviceUtil.assignPageSizeAndPageNumberIfNotPresent(queryParams);

    try {
      const response = await http.backendInstance.get("/api/survey/researcher-survey-response-list", {
        params: { ...queryParams },
        signal
      });

      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request was canceled:", error.message);
      } else {
        console.error("Error in service call:", error.message);
      }

      return [];
    }
  },
  getParticipantSurveys: async (queryParams, signal) => {
    const response = await http.backendInstance.get("/api/survey/participant-survey-list", {
      params: { ...queryParams },
      signal
    });

    return response.data;
  },
  getParticipantSurveyResponses: async (queryParams, signal) => {
    const response = await http.backendInstance.get("/api/survey/participant-survey-response-list", {
      params: { ...queryParams },
      signal
    });

    return response.data;
  },
  isSurveyTabAvailable: async (researchId) => {
    const response = await http.backendInstance.get(`/api/survey/is-survey-tab-available/${researchId}`);
    return response.data;
  },
  userCanAddSurvey: async () => {
    const response = await http.backendInstance.get("/api/survey/can-add-survey");
    return response.data;
  },
  userCanEditSurveyResponse: async (surveyTaskId) => {
    const response = await http.backendInstance.get(`/api/survey/can-edit-survey-response/${surveyTaskId}`);
    return response.data;
  },
  userCanWithdrawSurveyResponse: async (surveyTaskId) => {
    const response = await http.backendInstance.get(`/api/survey/can-withdraw-survey-response/${surveyTaskId}`);
    return response.data;
  },
  canAddSurveyParticipants: async (formData) => {
    const response = await http.backendInstance.post(
      "/api/survey/can-add-survey-participants",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  },

};

export default surveyService;
