import axios from "axios";
import { configureStore } from '../redux/store';
import {
  setAccessToken,
  setAuthenticated,
  setAccessRights,
  setTokenLifeTime,
  setAssignedGroupsCode,
  setSingleUserRole
} from "../redux/actions/auth";
import { setBearerHeader } from "../api/auth/auth";
import { UserRoleCodeFromNameMap, LocalStorageKey, SessionStorageKey } from "../enums";

let refreshAuth = false;
let refresh = false;

const currentSessionAccessToken = localStorage.getItem(LocalStorageKey.AccessToken)?.length ? localStorage.getItem(LocalStorageKey.AccessToken) : null;

const authInstance = axios.create({
  baseURL: process.env.REACT_APP_AUTH_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

authInstance.interceptors.response.use(
  response => {
    const {
      data: { data },
    } = response;
    response.data = data;

    return Promise.resolve(response);
  },
  async error => {
    if (error?.response?.status === 401 && !refreshAuth) {
      refreshAuth = true;

        const response = await axios.post(`${process.env.REACT_APP_AUTH_URL}/api/auth/refreshToken`, {}, {
          withCredentials: true, headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${currentSessionAccessToken}`
          }
        });

        if (response.status === 200) {
          const { accessToken, rights, tokenLifeTime } = response.data.data;
          localStorage.setItem(LocalStorageKey.AccessToken, accessToken);
          configureStore.dispatch(setAccessToken(accessToken));
          configureStore.dispatch(setAccessRights(rights));
          configureStore.dispatch(setTokenLifeTime(tokenLifeTime));
          configureStore.dispatch(setAuthenticated(true));
          setBearerHeader(accessToken);
          const userRoleName = sessionStorage.getItem(SessionStorageKey.Role);
          configureStore.dispatch(setSingleUserRole(
            {
              code: UserRoleCodeFromNameMap.get(userRoleName),
              name: userRoleName
            }
          ));

          backendInstance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
          authInstance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

          return authInstance(error.config);
        }
    }

    refreshAuth = false;

    return Promise.reject(error);
  }
);

const backendInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json"
  },
  withCredentials: true,
});

backendInstance.interceptors.response.use(
  response => {
    const { data: { data } } = response;
    if (response.data.hasOwnProperty("data"))
      response.data = data;

    return Promise.resolve(response);
  },
  async error => {
    if (error?.response?.status === 401 && !refresh) {
      refresh = true;

        const response = await axios.post(`${process.env.REACT_APP_AUTH_URL}/api/auth/refreshToken`, {}, {
          withCredentials: true, headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${currentSessionAccessToken}`
          }
        });
        if (response.status === 200) {
          const { accessToken, rights, tokenLifeTime, groupsAssignedCode } = response.data.data;

          localStorage.setItem(LocalStorageKey.AccessToken, accessToken);
          configureStore.dispatch(setAccessToken(accessToken));
          configureStore.dispatch(setAccessRights(rights));
          configureStore.dispatch(setTokenLifeTime(tokenLifeTime));
          configureStore.dispatch(setAuthenticated(true));
          configureStore.dispatch(setAssignedGroupsCode(groupsAssignedCode));
          const userRoleName = sessionStorage.getItem(SessionStorageKey.Role);
          configureStore.dispatch(setSingleUserRole(
            {
              code: UserRoleCodeFromNameMap.get(userRoleName),
              name: userRoleName
            }
          ));
          backendInstance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
          authInstance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

          return backendInstance(error.config);
        }
    }
    refresh = false;

    return Promise.reject(error);
  }
);

const auditInstance = axios.create({
  baseURL: process.env.REACT_APP_AUDIT_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const adminInstance = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export default {
  authInstance,
  backendInstance,
  auditInstance,
  adminInstance,
};
