import React from 'react';
import { BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { userAccessToken } from "./redux/selectors/authSelector";
import { RefreshAccessToken } from "./api/auth/auth";
import { SelectedRowProvider } from './components/SelectedRowProvider';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Router from './routes/routes'

const restrictedComponentsForTokenRefresh = [
  "/",
  "/latvija-lv",
  "/reset-password",
  "/set-password",
  "/role",
  "/more-information",
  "/contacts",
  "/participants/add",
];

const App = () => {
  const { customTheme } = useTheme();
  const token = useSelector(userAccessToken);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const allowTokenRefresh = !restrictedComponentsForTokenRefresh.includes(
      window.location.pathname
    );
    if (allowTokenRefresh && !token?.length)
      dispatch(RefreshAccessToken());

  }, [dispatch, token]);

  return (
    <SelectedRowProvider>
      <ThemeProvider theme={customTheme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <BrowserRouter>
            <Router/>
          </BrowserRouter>
        </LocalizationProvider>
      </ThemeProvider>
    </SelectedRowProvider>
  );
};

export default App;