import http from "../api/http";

const userActivityService = {
    getUserActivities: async (queryParams, signal) => {
        const response = await http.auditInstance.get(
            "/api/useractivity/list?", {
                params: queryParams,
                signal
            }
        );
        return response.data;
    }
};

export default userActivityService;