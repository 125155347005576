import http from "../api/http";

const resultService = {
    createResearchResult: async (resultToCreate, config = {}) => {
        const response = await http.backendInstance.post(
            "/api/result/research",
            resultToCreate,
            {
                ...config,
                headers: { "Content-Type": "multipart/form-data" },
            }
        );

        return response.data;
    },
    updateResearchResult: async (resultToUpdate, config = {}) => {
        const response = await http.backendInstance.put(
            "/api/result/research",
            resultToUpdate,
            {
                ...config,
                headers: { "Content-Type": "multipart/form-data" },
            }
        );

        return response.data;
    },
    publishResearchResults: async (researchResultId, signal) => {
        const response = await http.backendInstance.put(`/api/result/publish/${researchResultId}`, signal);

        return response.data;
    },
    sendForPublicationResearchResults: async (researchResultId, signal) => {
        const response = await http.backendInstance.put(`/api/result/send-for-publication/${researchResultId}`, signal);

        return response.data;
    },
    getResearchResults: async (researchId, signal) => {
        const response = await http.backendInstance.get(`/api/result/research/${researchId}`, signal);

        return response.data;
    },
    createUserResult: async (userResultData, signal) => {

        const response = await http.backendInstance.post(
            "/api/result/add-participant-results",
            userResultData,
            {
              headers: { "Content-Type": "multipart/form-data" },
              signal: signal
            }
          );

        return response.data;
    },
    publishUserResults: async (publishUserResultsData, signal) => {
        const response = await http.backendInstance.put("/api/result/publish-user-results", publishUserResultsData, signal);

        return response.data;
    },
    getUserResults: async (queryParams, signal) => {
        const response = await http.backendInstance.get("/api/result/research-users-results-list", {
            params: { ...queryParams },
            signal
        });

        return response.data;
    },
    getParticipantUserResults: async (queryParams, signal) => {
        const response = await http.backendInstance.get("/api/result/user-results", {
            params: { ...queryParams },
            signal
        });

        return response.data;
    },
    sendUserResultQuestion: async (questionData, signal) => {
        const response = await http.backendInstance.post("/api/result/send-question", questionData, signal);

        return response.data;
    },
    deleteAttachment: async (userResultId, signal) => {
        const response = await http.backendInstance.delete(`/api/result/attachments/${userResultId}`, signal);

        return response.data;
    }
};

export default resultService;
