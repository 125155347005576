import ROLE from "../../roles/roles";
import RIGHTS from "../../rights_enum";
import React from "react";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import { Link as RouterLink } from "react-router-dom";
import Logo from "./img/Decide Logo.png";
import DropdownMenu from "../dropdownMenu/dropdownMenu";
import classes from "./header.module.scss";
import HamburgerMenu from "../../MUI_components/HamburgerMenu";
import { useTranslation } from "../../hooks/useTranslation";
import useRole from "../../hooks/useRole";
import useToken from "../../hooks/useToken";
import Hoverable from "../dropdownMenu/Hoverable";
import { ResearchStatusEnum } from "../../enums";

export const HeaderDown = () => {
  const {
    MainMenuPersons,
    MainMenuPersonsList,
    MainMenuResearch,
    MainMenuPersonsAddNew,
    MainMenuMoreInfo,
    MainMenuContacts,
    MainMenuAboutUs,
    MainMenuReferral,
    MainMenuReferralAddNew,
    MainMenuReferralList,
    MainMenuSurvey,
    MainMenuSignedConsent,
  } = useTranslation();

  const {
    role: { name },
  } = useRole();

  const { rights, token } = useToken();

  const isCurrentUserAuthorized = sessionStorage.getItem("isAuthenticated")
    ? true
    : false;

  if (
    (name === ROLE.Researcher || name === ROLE.Admin || name === ROLE.Doctor) &&
    rights.includes(RIGHTS.RES_READ)
  ) {
  }

  function showPermissionElements() {
    return name === ROLE.Researcher || name === ROLE.Admin;
  }

  function showListOfConsentsSubmittedByUser() {
    return name === ROLE.Participant && isCurrentUserAuthorized;
  }

  function showResearcherSurveyList() {
    return (
      name === ROLE.Researcher ||
      name === ROLE.Admin ||
      rights.includes(RIGHTS.CanReadSurvey)
    );
  }

  function showParticipantSurveyList() {
    return name === ROLE.Participant;
  }

  function showQuestionList() {
    return (
      name === ROLE.Researcher ||
      name === ROLE.Admin ||
      rights.includes(RIGHTS.CanReadQuestion)
    );
  }

  function showBiobankConsentTab() {
    const allowedUserRoles = [ROLE.Participant, ROLE.Admin];

    return (
      allowedUserRoles.some((roleName) => roleName === name) &&
      isCurrentUserAuthorized
    );
  }

  function showSignedConsentTab() {
    const allowedUserRights = [
      RIGHTS.CanReadSignedConsentAll,
      RIGHTS.CanReadSignedConsentResearcher,
      RIGHTS.CanReadSignedConsentUser,
    ];

    return rights.some((right) => allowedUserRights.includes(right));
  }

  return (
    <>
      <header id="header" className={classes.header}>
        <div className={classes.header__logo}>
          <Link component={RouterLink} to={token ? "/dashboard" : "/"}>
            <img
              className={classes.logo__image}
              src={Logo}
              alt={"logo image"}
            />
          </Link>
        </div>
        <HamburgerMenu />
        <nav className={classes.nav__links}>
          <Hoverable pathName={"/about"}>
            <Link
              color="black"
              sx={{ marginLeft: "24px" }}
              component={RouterLink}
              to="/about"
              className={classes.link}
            >
              {MainMenuAboutUs}
            </Link>
          </Hoverable>
          <DropdownMenu
            id="research"
            menuName={MainMenuResearch}
            ariaControls={"research-menu"}
            ariaLabelledBy={"research-link"}
          >
            {({ handleClose }) => (
              <div>
                <Link
                  component={RouterLink}
                  to={{
                    pathname: "/public/researches",
                    search: `?status=${ResearchStatusEnum.Published}`,
                  }}
                  color="black"
                >
                  <MenuItem onClick={handleClose}>
                    {"Aktuālie pētījumi"}
                  </MenuItem>
                </Link>
                <Link
                  component={RouterLink}
                  to={{
                    pathname: "/public/researches",
                    search: `?status=${ResearchStatusEnum.Completed}`,
                  }}
                  color="black"
                >
                  <MenuItem onClick={handleClose}>
                    {"Iepriekš veiktie pētījumi"}
                  </MenuItem>
                </Link>
                {(name === ROLE.Researcher ||
                  name === ROLE.Admin ||
                  name === ROLE.Doctor) &&
                  rights.includes(RIGHTS.RES_READ) && (
                    <Link component={RouterLink} color="black" to="/researches">
                      <MenuItem onClick={handleClose}>
                        Pētījumu saraksts
                      </MenuItem>
                    </Link>
                  )}
                {showPermissionElements() && (
                  <Link component={RouterLink} color="black" to="/permissions">
                    <MenuItem onClick={handleClose}>
                      {"Piekrišanas elementu saraksts"}
                    </MenuItem>
                  </Link>
                )}
                {showListOfConsentsSubmittedByUser() && (
                  <Link
                    component={RouterLink}
                    color="black"
                    to="/consents/user-submitted-consents"
                  >
                    <MenuItem onClick={handleClose}>
                      {"Informēto piekrišanu saraksts"}
                    </MenuItem>
                  </Link>
                )}
                {showBiobankConsentTab() && (
                  <Link component={RouterLink} color="black" to="/biobank">
                    <MenuItem onClick={handleClose}>
                      {"Biobankas informētā piekrišana"}
                    </MenuItem>
                  </Link>
                )}
              </div>
            )}
          </DropdownMenu>
          {isCurrentUserAuthorized && (
            <DropdownMenu
              id="survey"
              menuName={MainMenuSurvey || "Anketas"}
              ariaControls={"survey-menu"}
              ariaLabelledBy={"survey-link"}
            >
              {({ handleClose }) => (
                <div>
                  {showResearcherSurveyList() && (
                    <Link component={RouterLink} color="black" to="/surveys">
                      <MenuItem onClick={handleClose}>Anketu saraksts</MenuItem>
                    </Link>
                  )}
                  {showParticipantSurveyList() && (
                    <Link
                      component={RouterLink}
                      color="black"
                      to="/surveys/participant-surveys"
                    >
                      <MenuItem onClick={handleClose}>Manas anketas</MenuItem>
                    </Link>
                  )}
                  {showQuestionList() && (
                    <Link component={RouterLink} color="black" to="/questions">
                      <MenuItem onClick={handleClose}>
                        Jautājumu saraksts
                      </MenuItem>
                    </Link>
                  )}
                </div>
              )}
            </DropdownMenu>
          )}

          {/* TODO Add translation for header */}
          {showSignedConsentTab() && (
            <Link
              color="black"
              sx={{ marginLeft: "24px" }}
              component={RouterLink}
              to="/signed-consents"
              className={classes.link}
            >
              {MainMenuSignedConsent || "PARAKSTĪTĀS PIEKRIŠANAS"}
            </Link>
          )}
          {(name === ROLE.Admin || name === ROLE.Doctor) && (
            <DropdownMenu
              id="persons"
              menuName={MainMenuPersons}
              ariaControls={"person-menu"}
              ariaLabelledBy={"person-link"}
            >
              {({ handleClose }) => {
                return (
                  <div>
                    {(rights.includes(RIGHTS.PERS_READ) ||
                      rights.includes(RIGHTS.PERS_READALL)) && (
                      <Link component={RouterLink} to="/persons" color="black">
                        <MenuItem onClick={handleClose}>
                          {MainMenuPersonsList}
                        </MenuItem>
                      </Link>
                    )}
                    {rights.includes(RIGHTS.PERS_CREATE) && (
                      <Link
                        component={RouterLink}
                        color="black"
                        to="/persons/add"
                      >
                        <MenuItem onClick={handleClose}>
                          {MainMenuPersonsAddNew}
                        </MenuItem>
                      </Link>
                    )}
                  </div>
                );
              }}
            </DropdownMenu>
          )}
          {(name === ROLE.Admin || name === ROLE.Researcher) && (
            <DropdownMenu
              id="referral"
              menuName={MainMenuReferral}
              ariaControls={"referral-menu"}
              ariaLabelledBy={"referral-link"}
            >
              {({ handleClose }) => {
                return (
                  <div>
                    {rights.includes(RIGHTS.REF_READ) && (
                      <Link
                        component={RouterLink}
                        to="/referrals"
                        color="black"
                      >
                        <MenuItem onClick={handleClose}>
                          {MainMenuReferralList}
                        </MenuItem>
                      </Link>
                    )}
                    {rights.includes(RIGHTS.REF_EDIT) && (
                      <Link
                        component={RouterLink}
                        color="black"
                        to="/referrals/add"
                      >
                        <MenuItem onClick={handleClose}>
                          {MainMenuReferralAddNew}
                        </MenuItem>
                      </Link>
                    )}
                  </div>
                );
              }}
            </DropdownMenu>
          )}
          <Hoverable pathName={"/more-information"}>
            <Link
              color="black"
              sx={{ marginLeft: "24px" }}
              component={RouterLink}
              to="/more-information"
              className={classes.link}
            >
              {MainMenuMoreInfo}
            </Link>
          </Hoverable>
          <Hoverable pathName={"/contacts"}>
            <Link
              color="black"
              sx={{ marginLeft: "24px" }}
              component={RouterLink}
              to="/contacts"
              className={classes.link}
            >
              {MainMenuContacts}
            </Link>
          </Hoverable>
        </nav>
      </header>
    </>
  );
};
