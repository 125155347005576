import { Navigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { SessionStorageKey } from "../enums";
import { useAccessToken } from "../hooks";

export const PrivateRoleRoute = ({ children }) => {
  const { isAuthenticated } = useAccessToken();
  const isRoleSelecting = sessionStorage.getItem(SessionStorageKey.IsRoleSelecting);

  if (!isRoleSelecting?.length && !isAuthenticated()) {
    return <Navigate to="/" replace />;
  }

  return children ? children : <Outlet />;
};
