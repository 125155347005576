const RIGHTS = Object.freeze({
  PERS_CREATE: "PERS_CREATE",
  PERS_READALL: "PERS_READALL",
  PERS_READ: "PERS_READ",
  PERS_DELETE: "PERS_DELETE",
  PERS_EDIT: "PERS_EDIT",
  USER_ADM_READ: "USER_ADM_READ",
  USER_ADM_EDIT: "USER_ADM_EDIT",
  USER_ADM_DELETE: "USER_ADM_DELETE",
  CLASSIFIER_READ: "CLASSIFIER_READ",
  CLASSIFIER_EDIT: "CLASSIFIER_EDIT",
  RES_READ: "RES_READ",
  RES_EXPORT: "RES_EXPORT",
  RES_CREATE: "RES_CREATE",
  RES_EDIT: "RES_EDIT",
  RES_PUBLISH: "RES_PUBLISH",
  RES_DELETE: "RES_DELETE",
  MESSAGE_CREATE: "MESSAGE_CREATE",
  CMS_READ: "CMS_READ",
  CMS_EDIT: "CMS_EDIT",
  REF_READ: "REF_READ",
  REF_EDIT: "REF_EDIT",
  CanEditConsents: "CONS_EDIT",
  CanReadConsentList: "CONSLIST_EDIT",
  CanReadSurvey: "SURVEY_VIEW",
  CanReadAdminSurvey: "SURVADM_READ",
  CanReadQuestion: "QUES_READ",
  CanReadResults: "RESULT_EDIT",
  CanReadParticipantResults: "PERM_EDIT",
  CanReadSignedConsentAll: "SIG_CONS_READ_ALL",
  CanReadSignedConsentResearcher: "SIG_CONS_READ_REASERCH",
  CanReadSignedConsentUser: "SIG_CONS_READ_USER",
});

export default RIGHTS;
